exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-admin-jsx": () => import("./../../../src/pages/admin.jsx" /* webpackChunkName: "component---src-pages-admin-jsx" */),
  "component---src-pages-control-jsx": () => import("./../../../src/pages/control.jsx" /* webpackChunkName: "component---src-pages-control-jsx" */),
  "component---src-pages-dashboard-jsx": () => import("./../../../src/pages/dashboard.jsx" /* webpackChunkName: "component---src-pages-dashboard-jsx" */),
  "component---src-pages-graph-jsx": () => import("./../../../src/pages/graph.jsx" /* webpackChunkName: "component---src-pages-graph-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-sparkline-jsx": () => import("./../../../src/pages/sparkline.jsx" /* webpackChunkName: "component---src-pages-sparkline-jsx" */),
  "component---src-pages-user-jsx": () => import("./../../../src/pages/user.jsx" /* webpackChunkName: "component---src-pages-user-jsx" */),
  "component---src-templates-doc-jsx-content-file-path-content-api-md": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/codebuild/output/src2771684013/src/ui/content/api.md" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-content-api-md" */),
  "component---src-templates-doc-jsx-content-file-path-content-architecture-index-md": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/codebuild/output/src2771684013/src/ui/content/architecture/index.md" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-content-architecture-index-md" */),
  "component---src-templates-doc-jsx-content-file-path-content-architecture-test-md": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/codebuild/output/src2771684013/src/ui/content/architecture/test.md" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-content-architecture-test-md" */),
  "component---src-templates-doc-jsx-content-file-path-content-index-md": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/codebuild/output/src2771684013/src/ui/content/index.md" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-content-index-md" */),
  "component---src-templates-doc-jsx-content-file-path-content-test-2-md": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/codebuild/output/src2771684013/src/ui/content/test-2.md" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-content-test-2-md" */)
}

